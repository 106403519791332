import {
  findHighestPercentageDiscount,
  getOverAllPercentageDiscount,
  getEarliestLatestDealsDateActive
} from '@utils/index';
import { DealPostType, CouponDetail, UserType } from '@types';
import { PromoCodeGroupType } from './components/CouponList';

const getPromoCodeGroupSortFn =
  (orderBy: string) => (a: PromoCodeGroupType, b: PromoCodeGroupType) => {
    if (orderBy === 'numPromoCodeDeals') {
      return b.numPromoCodeDeals - a.numPromoCodeDeals;
    }

    if (orderBy === 'highestDiscount') {
      return b.highestDiscount - a.highestDiscount;
    }

    if (orderBy === 'cheapestdeals') {
      return a.lowestFinalPrice - b.lowestFinalPrice;
    }

    return b.earliestDateActive.getTime() - a.earliestDateActive.getTime();
  };

export const getLatestDealsByPromoCodeGrouped = (
  latestDealsWithPromoCode: DealPostType[]
) => {
  return latestDealsWithPromoCode.reduce(
    (acc, latestDeal) => {
      const { promoCode } = latestDeal;
      if (!acc[promoCode]) {
        acc[promoCode] = [];
      }
      acc[promoCode].push(latestDeal);
      return acc;
    },
    {} as {
      [promoCode: string]: DealPostType[];
    }
  );
};

const getCouponDetailByPromoCode = (
  promoCode: string,
  couponDetails: CouponDetail[]
) => {
  const couponDetail = couponDetails?.find(
    (couponDetail) => couponDetail.promoCode === promoCode
  );

  return couponDetail || null;
};

export const getLatestDealsWithPromoCodeGroupedOrdered = ({
  latestDealsWithPromoCodeGrouped,
  orderBy,
  singleCode,
  couponDetails,
  user
}: {
  orderBy: string;
  latestDealsWithPromoCodeGrouped: {
    [promoCode: string]: DealPostType[];
  };
  singleCode: string;
  couponDetails: CouponDetail[] | null;
  user: UserType | null;
}) => {
  return Object.entries(latestDealsWithPromoCodeGrouped)
    .map(([promoCode, latestDeals]) => {
      const getLowestFinalPrice = (latestDeals: DealPostType[]) => {
        return latestDeals.reduce((acc, latestDeal) => {
          if (latestDeal.finalPrice < acc) {
            return latestDeal.finalPrice;
          }

          return acc;
        }, Number.MAX_SAFE_INTEGER);
      };

      const sortLatestDeals = (latestDeals: DealPostType[]) => {
        if (orderBy === 'cheapestdeals') {
          return latestDeals.sort((a, b) => {
            return a.finalPrice - b.finalPrice;
          });
        }

        if (orderBy === 'highestDiscount') {
          return latestDeals.sort((a, b) => {
            const discountA = getOverAllPercentageDiscount(a);
            const discountB = getOverAllPercentageDiscount(b);

            if (discountB !== discountA) {
              return discountB - discountA;
            }

            // Assuming each deal has a unique 'ASIN' property to break ties
            return a.ASIN.localeCompare(b.ASIN);
          });
        }

        return latestDeals;
      };

      return {
        promoCode,
        latestDeals: sortLatestDeals(latestDeals),
        numPromoCodeDeals: latestDeals.length,
        couponFixed: latestDeals[0]?.couponFixed,
        couponPercentage: latestDeals[0]?.couponPercentage,
        earliestDateActive: getCouponDetailByPromoCode(promoCode, couponDetails)
          ?.createdAt
          ? new Date(
              getCouponDetailByPromoCode(promoCode, couponDetails)?.createdAt
            )
          : getEarliestLatestDealsDateActive(latestDeals),
        lowestFinalPrice: getLowestFinalPrice(latestDeals),
        image:
          getCouponDetailByPromoCode(promoCode, couponDetails)?.image || '',
        imageHeight:
          getCouponDetailByPromoCode(promoCode, couponDetails)?.imageHeight ||
          0,
        imageWidth:
          getCouponDetailByPromoCode(promoCode, couponDetails)?.imageWidth || 0,
        highestDiscount: findHighestPercentageDiscount(latestDeals),
        hasUsedCouponAlready: !!(user?.allUsedCoupons || []).some(
          (usedCoupon) => usedCoupon.couponId === promoCode
        )
      };
    })
    .filter((promoCodeGroup) =>
      !singleCode ? promoCodeGroup.numPromoCodeDeals > 0 : true
    )
    .sort(getPromoCodeGroupSortFn(orderBy));
};

export const getAllDealsToRender = (
  latestDealsWithPromoCodeGroupedOrdered: PromoCodeGroupType[],
  searchFilterDebounced: string
) => {
  return latestDealsWithPromoCodeGroupedOrdered.filter((promoCodeGroup) => {
    if (searchFilterDebounced === '') {
      return true;
    }

    const searchTermLower = searchFilterDebounced.toLowerCase();
    const brands = promoCodeGroup.latestDeals.map(
      (latestDeal) => latestDeal.brand
    );

    const brandsLower = brands.map((brand) => brand.toLowerCase());

    const titles = promoCodeGroup.latestDeals.map(
      (latestDeal) => latestDeal.title
    );

    const titlesLower = titles.map((title) => title.toLowerCase());

    const allBrandsAndTitles = [...brandsLower, ...titlesLower];

    return allBrandsAndTitles.some((brandOrTitle) =>
      brandOrTitle.includes(searchTermLower)
    );
  });
};
