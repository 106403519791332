/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios from 'axios';
import { useQuery, useMutation, UseQueryResult } from 'react-query';
import queryClient from '../queryClient';
import { PromotionDisplayType, PromotionsType } from '../types';

const queryPromotions = async (): Promise<PromotionDisplayType[]> => {
  const result = await axios.get(`/api/current-promotions`);
  return result.data;
};

export function useGetPromotions(): UseQueryResult<PromotionDisplayType[]> {
  return useQuery<PromotionDisplayType[], Error>(
    `product-promotions`,
    () => queryPromotions(),
    {
      onError: () => {
        console.error(
          'Something went wrong while fetching the product promotions'
        );
      }
    }
  );
}

interface AdminPromotionsType {
  promotions: PromotionsType;
  unapprovedPromotions: PromotionsType;
}

export function useGetAdminPromotions(): UseQueryResult<AdminPromotionsType> {
  const queryAdminPromotions = async (): Promise<AdminPromotionsType> => {
    const { data } = await axios.get(`/api/dashboard/getPromotions`);
    const { promotions = [], unapprovedPromotions = [] } = data as {
      promotions: PromotionsType;
      unapprovedPromotions: PromotionsType;
    };
    return {
      promotions,
      unapprovedPromotions
    };
  };

  return useQuery<AdminPromotionsType, Error>(
    `admin-promotions`,
    () => queryAdminPromotions(),
    {
      onError: () => {
        console.error(
          'Something went wrong while fetching the product promotions'
        );
      }
    }
  );
}

export function useAddPromotion() {
  interface RequestAddPromotionData {
    link: string;
    name: string;
  }

  const mutationFn = async ({
    link,
    name
  }: RequestAddPromotionData): Promise<null> => {
    return axios.post(`/api/dashboard/addPromotion`, {
      link,
      name
    });
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries('admin-promotions');
      queryClient.refetchQueries('user-data');
      queryClient.refetchQueries('site-metrics');
    },
    onError: () => {
      console.error('error removing promotion');
    }
  });
}
