import _ from 'lodash';
import { formatPrice, getBrandsString } from '@utils/index';
import { DealPostType, UserType } from '@types';

function getUniqueBrands(brands: string[]): string[] {
  const groupedBrands = _.groupBy(brands, (brand) => brand.toLowerCase());
  return _.map(groupedBrands, (group) =>
    _.maxBy(group, (brand) => (brand[0] === brand[0].toUpperCase() ? 1 : 0))
  );
}

const getAllBrandsFromDeals = (deals: DealPostType[]): string[] => {
  return (deals || [])
    .filter((ld) => ld?.brand !== null && ld?.brand !== '')
    .map((latestDeal) => latestDeal.brand);
};

export const getUniqueBrandsFromDeals = (deals: DealPostType[]): string[] => {
  return getUniqueBrands(getAllBrandsFromDeals(deals));
};

export const getLowestFinalPriceFromDeals = (deals: DealPostType[]): number => {
  return deals.reduce((acc, latestDeal) => {
    if (latestDeal.finalPrice < acc) {
      return latestDeal.finalPrice;
    }

    return acc;
  }, Number.MAX_SAFE_INTEGER);
};

export const getCouponText = (
  allUniqueBrands: string[],
  numPromoCodeDeals: number,
  msgCouponAmount: string
) => {
  // Return a generic message if there are no unique brands
  if (allUniqueBrands.length === 0) {
    return 'See all the coupon details on Amazon';
  }

  // Construct the text for the number of deals
  const dealCountText =
    numPromoCodeDeals === 1 ? 'one deal' : `${numPromoCodeDeals} deals`;

  // Determine the preposition based on the number of promo code deals
  const preposition = numPromoCodeDeals > 1 ? 'one of' : '';

  // Generate the brands string from the array of brands
  const brandsText = getBrandsString([...allUniqueBrands]);

  // Construct the final message with the correct grammar
  return `${msgCouponAmount} on ${preposition} ${dealCountText} from ${brandsText}`;
};

export const getSuggestedSubject = (
  latestDeals: DealPostType[],
  couponMsg: string,
  lowestFinalPrice: number
) => {
  return latestDeals.length > 5
    ? `Get an extra ${couponMsg} with deals from ${formatPrice(
        lowestFinalPrice
      )}!`
    : `Use this coupon to get an extra ${couponMsg}!`;
};

export const getDefaultEmailText = (
  allUniqueBrands: string[],
  numPromoCodeDeals: number,
  msgCouponAmount: string
) => {
  return `For a limited time ONLY, Amazon has a ${msgCouponAmount} coupon that you can use on ${
    numPromoCodeDeals > 1 ? 'one of' : ''
  } ${numPromoCodeDeals} ${numPromoCodeDeals > 1 ? 'deals' : 'deal'} from ${[
    ...allUniqueBrands
  ]
    .splice(0, 3)
    .join(', ')}${allUniqueBrands.length > 3 ? ' and more' : ''}!`;
};

export const getIfhasUsedCouponAlready = (
  user: UserType | null,
  promoCode: string
) => {
  return (
    promoCode &&
    !!(user?.allUsedCoupons || []).some(
      (usedCoupon) => usedCoupon.couponId === promoCode
    )
  );
};
