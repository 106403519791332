import React, { memo } from 'react';
import { formatDistance, isValid } from 'date-fns';
import {
  Box,
  Typography,
  Grid,
  GridProps,
  CardContent,
  Card,
  CardActions,
  List,
  CardProps,
  Paper
} from '@mui/material';
import { useTheme } from '@mui/system';
import config from '@configFile';
import { useGetUserData } from '@hooks/useGetUserData';
import IsCouponUsed from '@components/IsCouponUsed';
import MonetizingLink from '@components/MonetizingLink';
import { getCouponLinkFromCode, getCouponOffMessage } from '@utils/index';
import AdminCouponActions from '@components/AdminActions/AdminCouponActions';
import { DealPostType } from '@types';
import CouponListItem from '../CouponListItem';
import CouponImage from '../CouponImage';
import {
  getUniqueBrandsFromDeals,
  getLowestFinalPriceFromDeals,
  getCouponText,
  getSuggestedSubject,
  getIfhasUsedCouponAlready,
  getDefaultEmailText
} from './utils';

export interface PromoCodeGroupType {
  promoCode: string;
  latestDeals: DealPostType[];
  numPromoCodeDeals: number;
  highestDiscount: number;
  lowestFinalPrice: number;
  couponFixed: any;
  couponPercentage: any;
  image: string;
  imageHeight: number;
  imageWidth: number;
  earliestDateActive?: Date;
}

interface CouponListProps {
  promoCodeGroup: PromoCodeGroupType;
  gridItemProps?: GridProps;
  max?: number;
  tag?: string;
  openExpanded?: boolean;
  noCard?: boolean;
}

const CouponList = ({
  promoCodeGroup,
  gridItemProps = {
    sm: 12
  },
  tag = config.AFFILIATE_TAGS.COUPON_PAGE,
  noCard = false
}: CouponListProps) => {
  const { data: user } = useGetUserData();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const {
    promoCode,
    couponFixed,
    couponPercentage,
    latestDeals,
    numPromoCodeDeals,
    image,
    imageWidth,
    imageHeight,
    earliestDateActive
  } = promoCodeGroup;

  const Container = noCard
    ? Box
    : (Card as React.ReactNode as React.ElementType);

  const props: CardProps = {};
  const cardProps = noCard
    ? {}
    : {
        elevation: 0,
        sx: {
          borderBottom: '1px solid #c1c4cc',
          borderRadius: '0px',
          ...(isDarkMode
            ? {
                border: `1px solid #c1c4cc`
              }
            : {})
        }
      };

  const hasUsedCouponAlready = getIfhasUsedCouponAlready(user, promoCode);
  const allUniqueBrands = getUniqueBrandsFromDeals(latestDeals);
  const lowestFinalPrice = getLowestFinalPriceFromDeals(latestDeals);
  const msgCouponAmount = getCouponOffMessage(couponFixed, couponPercentage);
  const couponMsg = getCouponText(
    allUniqueBrands,
    numPromoCodeDeals,
    msgCouponAmount
  );
  const defaultEmailText = getDefaultEmailText(
    allUniqueBrands,
    numPromoCodeDeals,
    msgCouponAmount
  );

  const suggestedSubject = getSuggestedSubject(
    latestDeals,
    couponMsg,
    lowestFinalPrice
  );

  return (
    <Grid
      item
      {...gridItemProps}
      sx={{
        width: '100%',
        padding: '8px'
      }}
      key={promoCodeGroup.promoCode}
      {...props}
    >
      <Container {...cardProps}>
        <CardContent
          elevation={4}
          component={Paper}
          sx={{
            padding: '1rem !important'
          }}
        >
          <CardActions
            sx={{
              padding: '0px',
              justifyContent: 'space-between'
            }}
            disableSpacing
          >
            <Box
              sx={{
                width: '100%'
              }}
            >
              {image ? (
                <CouponImage
                  image={image}
                  imageHeight={imageHeight}
                  imageWidth={imageWidth}
                  promoCode={promoCode}
                  tag={tag}
                />
              ) : null}
              <MonetizingLink
                href={getCouponLinkFromCode(promoCode, tag)}
                target="_blank"
                sx={{
                  color: theme.palette.linkColor,
                  fontWeight: 700
                }}
                tag={tag}
                clickType="amazon-coupon-page-active-link"
              >
                <Typography
                  component="span"
                  variant="h6"
                  sx={{
                    fontWeight: 700
                  }}
                >
                  {couponMsg}
                </Typography>
              </MonetizingLink>
              {earliestDateActive && isValid(earliestDateActive) ? (
                <Box>
                  <Typography variant="caption">
                    {`Coupon first added ${formatDistance(
                      earliestDateActive,
                      new Date(),
                      {
                        addSuffix: true
                      }
                    )}`}
                  </Typography>
                </Box>
              ) : null}
            </Box>
            <AdminCouponActions
              suggestedSubject={suggestedSubject}
              promoCode={promoCode}
              brands={allUniqueBrands}
              defaultEmailText={defaultEmailText}
            />
          </CardActions>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                paddingTop: '12px'
              }}
            >
              <IsCouponUsed
                promoCode={promoCode}
                tag={tag}
                ASIN={latestDeals?.[0]?.ASIN || ''}
                showCouponMessage={false}
                shouldClaimedCoupon={false}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'inline',
              opacity: hasUsedCouponAlready ? '0.4' : '1'
            }}
          >
            <List>
              {latestDeals.map((latestDeal) => {
                return (
                  <CouponListItem
                    key={latestDeal.ASIN}
                    latestDeal={latestDeal}
                    tag={tag}
                  />
                );
              })}
            </List>
          </Box>
        </CardContent>
      </Container>
    </Grid>
  );
};

export default memo(CouponList);
