import React, { memo } from 'react';
import { Box, CardMedia } from '@mui/material';
import { getCouponLinkFromCode } from '@utils/index';
import MonetizingLink from '@components/MonetizingLink';
import colors from '@utils/colors';

const CouponImage = ({
  image,
  imageHeight,
  imageWidth,
  promoCode,
  tag
}: {
  image: string;
  imageHeight: number;
  imageWidth: number;
  promoCode: string;
  tag: string;
}) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        marginBottom: '10px',
        maxHeight: `${imageHeight}px`
      }}
    >
      <MonetizingLink
        href={getCouponLinkFromCode(promoCode, tag)}
        target="_blank"
        sx={{
          color: colors.linkColor,
          fontWeight: 700
        }}
        tag={tag}
        clickType="amazon-coupon-page-active-image-link"
      >
        <CardMedia
          component="img"
          image={image}
          sx={{
            maxWidth: imageWidth
          }}
        />
      </MonetizingLink>
    </Box>
  );
};

export default memo(CouponImage);
