import React from 'react';
import { Tooltip, Typography, TypographyProps } from '@mui/material';
import { useTheme } from '@mui/system';
import { DealPostType } from '@types';
import { isDateWithinLast24Hours } from '@utils/index';

const NewTooltip = ({
  deal,
  usePostDate = false,
  label = '*New!*',
  variant = 'body1'
}: {
  deal: DealPostType;
  usePostDate?: boolean;
  label?: string;
  variant?: TypographyProps['variant'];
}) => {
  const theme = useTheme();
  const { dateFirstActive, postDate } = deal;
  const isNew = !!(
    dateFirstActive && isDateWithinLast24Hours(new Date(dateFirstActive))
  );

  const isNewPostDate = !!(
    postDate && isDateWithinLast24Hours(new Date(postDate))
  );

  if ((!usePostDate && !isNew) || (usePostDate && !isNewPostDate)) {
    return null;
  }

  const standardLabelStyles = {
    color: theme.palette.newColor,
    fontWeight: 700,
    marginRight: '4px',
    fontSize: '1rem',
    lineHeight: '22px'
  };

  return (
    <Tooltip title="Added in the past 24 hours">
      <Typography
        display="inline"
        variant={variant}
        sx={
          variant === 'caption'
            ? {
                ...standardLabelStyles,
                lineHeight: '1.4',
                fontSize: '12px'
              }
            : standardLabelStyles
        }
      >
        {label}
      </Typography>
    </Tooltip>
  );
};

export default NewTooltip;
