import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import config from '@configFile';

const Disclaimer = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  return (
    <Typography
      variant="caption"
      color="text.secondary"
      display="block"
      sx={{
        fontStyle: 'italic',
        fontSize: '11px',
        lineHeight: '15px',
        paddingBottom: '2px',
        color: isDarkMode ? '#ababab' : '#595959'
      }}
    >
      {config.disclaimer}
    </Typography>
  );
};

export default Disclaimer;
