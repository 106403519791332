import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import IconButton from '@mui/material/IconButton';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useGetUserData } from '@hooks/useGetUserData';
import {
  useRedoCouponImage,
  useUpdateCouponByPromoCode
} from '@hooks/useCoupons';
import { useUpdateDealsByBrand } from '@hooks/useDeals';
import { useSnackbar } from '@hooks/useSnackbar';
import { copyTextToClipboard } from '@utils/index';
import SendMailing from '@components/SendMailing';
import { iconButtonHoverRaiseStyle } from '@utils/styles';

const AdminCouponActions = ({
  promoCode,
  suggestedSubject,
  defaultEmailText,
  brands = []
}: {
  promoCode: string;
  suggestedSubject: string;
  defaultEmailText: string;
  brands?: string[];
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data: user } = useGetUserData();
  const { showMessage } = useSnackbar();
  const { mutate: updateCoupon } = useUpdateCouponByPromoCode();
  const { mutate: updateDealsByBrand } = useUpdateDealsByBrand();
  const { mutate: redoCouponImage } = useRedoCouponImage();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyLink = () => {
    copyTextToClipboard(
      `${suggestedSubject}
https://www.jungle.deals/amazon-coupon-deals/${promoCode}`
    );

    showMessage('Copied to clipboard!');
  };

  const open = Boolean(anchorEl);

  const processDealNow = (e: React.MouseEvent) => {
    e.stopPropagation();
    updateCoupon(
      {
        promoCode
      },
      {
        onSuccess: () => {
          handleClose();
          showMessage('Rechecking all deals with this coupon...');
        }
      }
    );
  };

  if (!user?.isAdmin) {
    return null;
  }

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.preventDefault();
          handleClick(e);
        }}
        id="admin-coupon-button"
        aria-label="settings"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        size="small"
        sx={iconButtonHoverRaiseStyle}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          key="copy-link"
          onClick={() => {
            handleCopyLink();
            handleClose();
          }}
        >
          <ListItemIcon>
            <ContentCopyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy Link</ListItemText>
        </MenuItem>
        <MenuItem
          key="admin-recheck-now"
          onClick={(e) => {
            processDealNow(e);
          }}
        >
          <ListItemIcon>
            <PostAddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Recheck now</ListItemText>
        </MenuItem>
        <MenuItem
          key="admin-redo-image"
          onClick={(e) => {
            e.stopPropagation();
            redoCouponImage(
              {
                promoCode
              },
              {
                onSuccess: () => {
                  showMessage(`Image redone. Reloading...`);
                }
              }
            );

            handleClose();
            showMessage(`Redoing image for coupon....`);
          }}
        >
          <ListItemIcon>
            <PostAddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Redo image</ListItemText>
        </MenuItem>
        {brands.map((brand) => (
          <MenuItem
            key={`admin-recheck-brand-${brand}`}
            onClick={(e) => {
              e.stopPropagation();
              updateDealsByBrand(
                {
                  brand
                },
                {
                  onSuccess: (data) => {
                    handleClose();
                    showMessage(
                      `Rechecking ${data?.rechecking || 0} ${brand} brand ${
                        data?.rechecking > 1 ? 'deals' : 'deal'
                      }...`
                    );
                  }
                }
              );
            }}
          >
            <ListItemIcon>
              <AssignmentTurnedInIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Recheck {brand} brand deals</ListItemText>
          </MenuItem>
        ))}
        <SendMailing
          appearance="menuItem"
          onCloseCallback={() => {
            handleClose();
          }}
          promoCode={promoCode}
          subject={suggestedSubject}
          defaultEmailText={defaultEmailText}
        />
      </Menu>
    </>
  );
};

export default AdminCouponActions;
