/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { DealPostType } from '../types';

const queryTopDeals = async (): Promise<DealPostType[]> => {
  const result = await axios.get(`/api/get-top-deals`);

  return result.data;
};

export function useGetTopDeals(): UseQueryResult<DealPostType[]> {
  return useQuery<DealPostType[], Error>(`top-deals`, () => queryTopDeals(), {
    onError: () => {
      console.error('Something went wrong while fetching the top deals');
    }
  });
}
