import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';

// Type definition for the props of the HOC, allows any component to be passed with its props
type LazyLoadedComponentProps<T> = {
  Component: React.ComponentType<T>; // Accept any React component type
  componentProps: T; // Props specific to the component being lazy-loaded
};

function LazyLoadedComponent<T>({
  Component,
  componentProps
}: LazyLoadedComponentProps<T>) {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Optionally unobserve after loading
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1 // Adjust this value based on how much of the item should be visible before triggering
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []); // Removed isVisible from dependency array to set up observer once

  return <Box ref={ref}>{isVisible && <Component {...componentProps} />}</Box>;
}

export default LazyLoadedComponent;
